.profileImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;

  .imageAndDetail {
    display: flex;
  }
}

label.label input[type="file"] {
  position: absolute;
  top: -1000px;
}

.label {
  cursor: pointer;
  //border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 5px 30px;
  margin: 27px;
  //background: #ffffff;
  display: inline-block;
  color: #5e2ec5;
}

.followers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .card {
    background-color: purple;
    color: white;
    padding: 0px;
    margin: 0px;
  }

  button {
    border: 2px solid white;

  }
}

.follow {
  button {
    border: 2px solid blue;
    width: 160px;
    padding: 5px 5px 5px 5px;
    border-radius: 25px;
    color: purple;
    background-color: #f5f5f5;

    &:hover {
      background-color: purple;
      color: #ffffff;
    }
  }
}

.nameClick {
  cursor: pointer;
}

.lightboxContainer {
  position: relative;
  display: inline-block;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.lightboxContainer:after {
  content: url("https://www.wonderplugin.com/download/playbutton.png");
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -32px;
  margin-top: -32px;
  cursor: pointer;
}

.lightboxContainer img {
  width: 100%;
  height: 100%;
  cursor: default;
}

.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #3e8e41;
}

/* The search field */
#myInput {
  box-sizing: border-box;
  //background-image: url('searchicon.png');
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
}

/* The search field when it gets focus/clicked on */
#myInput:focus {
  outline: 3px solid #ddd;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  border: 1px solid #ddd;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

.filter-refer {
  .btn {
    border: 0 none;
    outline: 0 none;
    box-shadow: none;
    background: none;
    padding: 0;
    margin: 0;
  }

  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    border: 0 none;
    outline: 0 none;
    box-shadow: none;
    background: none;
  }

  .dropdown-menu {
    margin-left: 0rem;
    margin-top: 15px;
    min-width: 4rem;
  }
}

.filter {
  .btn {
    border: 0 none;
    outline: 0 none;
    box-shadow: none;
    background: none;
    padding: 0;
    margin: 0;
  }

  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    border: 0 none;
    outline: 0 none;
    box-shadow: none;
    background: none;
  }

  .dropdown-menu {
    margin-left: -4rem;
    margin-top: 15px;
    min-width: 4rem;
  }
}

.star-rating {
  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .on {
    color: #ffd642;
  }

  .off {
    color: #ccc;
  }
}

.rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.rating-list {
  display: flex;
  flex-direction: column;
  width: 100%;

  .card {
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 15px;
  }

  .card-image {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .card-name-star {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .star {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    button {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      margin: 0;
      padding: 0;
    }

    .on {
      color: #ffd642;
    }

    .off {
      color: #ccc;
    }
  }

}

.circular_image {
  border-radius: 50%;
  overflow: hidden;
  background-color: rgba(0, 0, 255, 0);
  object-fit: contain;
  /* commented for demo
  float: left;
  margin-left: 125px;
  margin-top: 20px;
  */

  /*for demo*/
  display: inline-block;
  vertical-align: middle;
}

.custom-btn {
  width: 130px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
  7px 7px 20px 0px rgba(0, 0, 0, .1),
  4px 4px 5px 0px rgba(0, 0, 0, .1);
  outline: none;
}

.btn-9 {
  border: none;
  transition: all 0.3s ease;
  overflow: hidden;
}

.btn-9:after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #a21ff9;
  background-image: linear-gradient(315deg, #d98bef 0%, #8e00d9 74%);
  transition: all 0.3s ease;
}

.btn-9:hover {
  background: transparent;
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, .5),
  -4px -4px 6px 0 rgba(116, 125, 136, .2),
  inset -4px -4px 6px 0 rgba(255, 255, 255, .5),
  inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
  color: #fff;
}

.btn-9:hover:after {
  -webkit-transform: scale(2) rotate(180deg);
  transform: scale(2) rotate(180deg);
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, .5),
  -4px -4px 6px 0 rgba(116, 125, 136, .2),
  inset -4px -4px 6px 0 rgba(255, 255, 255, .5),
  inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
}

.trending-button {
  background-color: #f6f6f6;
  color: black;

  &:hover {
    background-color: #5754af;
    color: white;
  }
}

.trending-div {
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: black;
    // background-color: purple;
    border-color: white;
    background-color: #2108c171;
  }
}

.all-btn {
  background-color: #4f2bab;
  // background-color: #511b80;
  
  border-radius: 6px;
  color: white;
  border-color: transparent;
  font-family: 'Lato', sans-serif;
  font-weight: 500;

  &:hover {
    background-color: white;
    color: #511b80;
    border-color: #511b80;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 220px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md.fixed-left + .main-content {
    margin-left: 220px;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-brand {
    display: block;
    text-align: center;
    padding-bottom: 0rem;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-brand-img {
    max-height: 2.8rem;
  }
}

@media (min-width: 768px) {
  .main-content .container-fluid {
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 5px;
  }
}

strong {
  font-weight: bold;
}

.scroll-container {
  height: 800px;
  overflow-y:scroll;
  // overflow: hidden;
  overflow-x: hidden;
  position: relative;
}

.scroll-container:hover {
  overflow: scroll;
  overflow-x: hidden;
}

.scroll-container::-webkit-scrollbar {
  width: 10px;
  opacity: 0;
  transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.scroll-container:hover::-webkit-scrollbar {
  width: 6px;
  opacity: 1;
}

.scroll-container::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-container::-webkit-scrollbar-thumb {
  background: #888;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.btn:not(:last-child) {
  margin-right: 0rem;
}

@media only screen and (max-width: 600px) {
  .player-wrapper {
    position: relative;
    padding-top: 75%; /* 16:9 ratio */
  }
  .col-xl-1 {
    width: 20%;
  }
}

.post-textarea {
  border: none;

}

.show-more:hover {
  color: purple;
  cursor: pointer
}

.like-hover {
  cursor: pointer;
}

.image-span {
  cursor: pointer
}

.image-span:hover {
  color: purple
}

.notification-main {
  background-color: white;
  border-radius: 8px;
  box-shadow: 1px 2px 5px lightgrey;
}

.lesson-history {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //margin-top: 10px;
  align-items: end;

  .tab-button {
    background-color: transparent;
    border: none;
    color: black;
  }

  .lesson-tab-search {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.history-list {
  display: flex;
  flex-direction: row;
  gap: 20px;

  .history-card {
    display: flex;
    width: 30%;
    box-shadow: 2px 4px 4px black;
    margin-top: 15px;

  }

  .youtube-content {
    display: flex;
    flex-direction: row;
    min-height: 200px;
  }
}


.youtube-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5%;
  margin-bottom: 10px;
}


@media only screen and (min-width: 768px) {
  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 16:9 ratio */
  }
  .player-change {
    height: 498px
  }
  .container-dashboard {
    padding: 10px;
    background-color: whitesmoke;
  }
  .notification-icon {
    height: 50px;
    width: 50px;
    background-color: #e8e8e8;
  }
  .notification-button {
    background-color: #4f2bab;
    color: white;
    cursor: pointer;
    width: 100px;
    //padding:0px 30px 0px 30px
  }
  .notification-icon-width {
    width: 10%
  }
  .mobile-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
  }

  .lesson-history {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // margin-top: 10px;

    .tab-button {
      background-color: transparent;
      border: none;
      color: black;
    }

    .lesson-tab-search {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .history-list {
    display: flex;
    flex-direction: row;

    .history-card {
      display: flex;
      width: 33.33%;
      box-shadow: 2px 4px 4px black;

      .youtube-content {
        display: flex;
        flex-direction: row;
        min-height: 200px;
      }

    }
  }
  .youtube-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5%;
    margin-bottom: 10px;

    .youtube-list-item {
      width: 32.33%;
      margin-top: 20px;
      background-color: transparent;
      box-shadow: 2px 4px 4px lightgrey;

      .history-card {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: white;
      }
    }
  }

  .write-review {
    margin-top: -40px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
  }
  .free-lesson-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;

    padding-left: 10%;
    padding-right: 10%;
  }
  .lesson-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 2%;

    .lesson-list-content {
      border-radius: 0.375rem;
      background-color: white;
      width: 31.3%;
      margin-bottom: 15px;
      box-shadow: 2px 4px 4px lightgrey;
      height: 275px;
    }
  }
  .youtube-title {
    .descriptionp {
      margin: 0px;
      padding: 0px;

      .p {
        margin: 0px;
        padding: 0px;
      }
    }
  }
  .search-group {
    display: flex;
    gap: 1%;
    flex-direction: row;
    margin-left: 20%;
    margin-right: 20%;
  }
}

@media only screen and (min-width: 768px) {

  .upload-image-wrapper {
    width: 100%;

    .upload-image-div {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;

      .upload-image {
        display: flex;
        width: 95%;
        gap: 3%;
        row-gap: 15px;
        height: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;

        .upload-list {
          position: relative;
          display: flex;
          flex-direction: column;
          box-shadow: 2px 2px 5px lightgrey;
          width: 31%;
          justify-content: center;
          align-items: stretch;
          border-radius: 5px;

          //.assignment-content {
          //  display: flex;
          //  flex-direction: row;
          //  height: 200px;
          //  max-height: 300px;
          //}

          .edit-assignment {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: transparent;
            border-color: transparent;
            box-shadow: none;
            cursor: pointer;

            &:hover {
              border-color: purple;
            }
          }
        }
      }
    }

    .btn-assignment {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .row-btn {
        width: 50%;
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }
  }
  .social-share-round {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
  }
  .social-share {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
  }
  .table-overflow {
    overflow-x: auto;
  }
  th, td {
    text-align: center;
    padding: 10px
  }
  .main-body {
    background-color: #DEEAF6;
    /*background-image: url('https://englishhub.app/static/images/logoopacity15.png');*/
    /*background-position: center center;*/
    /*background-repeat: repeat-y;*/

  }

  .btn-link:hover {
    transition: width 2s, height 2s;
  }

  .nav-check {
    background-color: #511b80;
    height: 70px
  }

  .navbar-custom {
    padding: 20px;
  }

  .left-bottom-posts {
    display: none;
  }

  .right-bottom-posts {
    display: none;
  }

  .web-mobile {
    position: relative;
    display: flex;
    flex-direction: row;
    color: black;
    padding-top: 7%;
    padding-left: 10px;
    padding-right: 10px;
  }


  .mob-webspan {
    text-align: center;
  }

  .center {
    padding-left: 20px;
    padding-right: 20px;
    width: 60%;
  }

  .bottom-posts {
    display: flex;
    flex-direction: row;
    /*background-color: rgba(45, 27, 127, 0.16);*/
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 2px 2px #DEEAF6;
    margin-bottom: 10px;
    gap: 10px;
  }

  .bottom-img-title {
    margin-top: 10px;
    width: 100%;
    min-height: 250px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 2px 2px 4px 4px rgba(45, 27, 127, 0.12);
    cursor: pointer;
    aspect-ratio: 1/1;
  }
  .bottom-img-title:hover {
    box-shadow: 4px 4px 4px 4px rgba(45, 27, 127, 0.12);
  }

  .background-opacity {
    background-image: url('https://englishhub.app/opacitylogo.png');
    background-position: center;
    background-repeat: repeat-y;
  }

  .left-add {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20%;
  }

  .right-add {
    width: 20%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 16:9 ratio */
  }

  footer {
    width: 100%;
    background-color: rgb(222, 234, 246);
  }

  .footer-containor1 {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
  }

  .footer-containor1 h {
    font-weight: 300;
    text-decoration: underline;
    margin-bottom: 4px;
    font-size: 20px;
    font-style: inherit;
  }

  .link-file {
    border-bottom: 0.2px solid white;
    /*border-radius: 12px 12px 0 0;*/
    /*margin: auto;
    */
    width: 100%;
    display: flex;
    /*justify-content: flex-start;
    */
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #511b80;
    overflow: hidden;
  }

  .link-top {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    flex-direction: row;
    width: 100%;
    height: 75px;
    align-items: center;
    /* height: 100px; */
  }

  .link-top img {
    width: 200px;
  }

  .link-top button {
    border: 1px solid whitesmoke;
    border-radius: 35px;
    padding: 13px 50px;
    position: relative;
    background: #511b80;
    color: white;
    cursor: pointer;
    -webkit-border-radius: 35px;
    /*! width: 100px; */
    -ms-border-radius: 35px;
    -o-border-radius: 35px;
    height: fit-content;
    margin-right: 12px;
    font-size: 18px;
    font-weight: 200;
    /*! display: flex; */
    /*! text-align: center; */
    font-family: unset;
  }

  .link-top button:hover {
    color: black;
    /*! font-stretch: extra-condensed; */
    font-size: 18px;
    border: black;
    background: white;
  }

  .link-bottom {
    display: flex;
    width: 70%;
    justify-content: center;
    margin: 10px;
  }

  .link-bottom a {
    margin: 0px 12px;
    color: white;
    font-size: 1.5rem;
    padding: 0px 0px;
    position: sticky;
  }

  .footer-containor2 {
    background-color: black;
    color: white;
    text-align: center;
    margin-top: 10px;
  }

  .footer-containor2 p {
    font-weight: 500;
    font-style: initial;
  }

  .footer-copyright {
    display: flex;
    background: #511b80;
    width: 100%;
    height: 20px;
    padding: 10px;
    flex-basis: 100%;
    color: white;
    font-weight: bold;
    font-style: initial;
    font-family: sans-serif;
    justify-content: flex-start;
    align-items: center;
  }

  p {
    font-family: sans-serif;
  }

  .social-menu ul {
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    display: flex;
  }

  .social-menu ul li {
    list-style: none;
    margin: 0 15px;
  }
  .link-top-mobile {
    display: flex;
    width: 30%;
  }

  .social-menu ul li .fab {
    font-size: 23px;
    line-height: 45px;
    transition: .3s;
    color: #000;
  }

  .social-menu ul li .fab:hover {
    color: #fff;
  }

  .social-menu ul li a {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #511b80;
    text-align: center;
    transition: .6s;
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, .5);
  }

  .social-menu ul li a:hover {
    transform: translate(0, -10%);
  }

  .social-menu ul li:nth-child(1) a:hover {
    background-color: rgba(39, 113, 206, 0.83);
  }

  .social-menu ul li:nth-child(2) a:hover {
    background-color: #f508e8;
  }

  .social-menu ul li:nth-child(3) a:hover {
    background-color: #fd3f06;
  }

  .social-menu ul li:nth-child(4) a:hover {
    background-color: #2d58e1;
  }

  .social-menu ul li:nth-child(5) a:hover {
    background-color: #d509f5;
  }

  .social-menu ul li:nth-child(6) a:hover {
    background-color: rgb(14, 220, 140);
  }

  .social-menu ul li:nth-child(7) a:hover {
    background-color: rgba(0, 0, 0, 0.89);
  }

  .left-bottom-ad {
    display: none;
  }

  .fontawesomesvg {
    width: 1em;
    height: 1em;
    vertical-align: -.125em;
  }

  .tiktok {
    width: 1em;
    height: 1em;
  }

}

@media only screen and (min-width: 1280px) {
  .people-know {
    // position: fixed;
    top: 0;
    right: 0;
    padding-left: 0px;
  }
  .full-card {
    height: calc(200vh - 76px - 118px);
  }
}

@media only screen and (max-width: 768px) {

  .upload-image-wrapper {
    width: 100%;
    height: 100%;

    .upload-image-div {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;

      .upload-image {
        display: flex;
        width: 90%;
        height: 100%;
        gap: 3%;
        row-gap: 20px;
        column-gap: 20px;
        flex-wrap: wrap;
        justify-content: flex-start;

        .upload-list {
          position: relative;
          display: flex;
          flex-direction: column;
          box-shadow: 2px 2px 5px lightgrey;
          width: 100%;
          justify-content: space-evenly;
          align-items: stretch;
          border-radius: 5px;

          //.assignment-content {
          //  display: flex;
          //  flex-direction: row;
          //  height: 180px;
          //  max-height: 300px;
          //}

          .edit-assignment {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: transparent;
            border-color: transparent;
            box-shadow: none;
            cursor: pointer;

            &:hover {
              border-color: purple;
            }
          }
        }
      }
    }

    .btn-assignment {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;

      .btn {
        width: 100%;
      }

      .row-btn {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }


  .youtube-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5%;
    margin-bottom: 10px;

    .youtube-list-item {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  .social-share-round {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
  }
  .social-share {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
  table {
    width: 100%;
    max-width: none;
    table-layout: fixed;
    font-size: 9px;
  }
  th, td {
    text-align: center;
    padding: 2px
  }
  .main-body {
    background-color: #DEEAF6;
    /*background-image: url('https://englishhub.app/static/images/logoopacity15.png');*/
    /*background-position: center center;*/
    /*background-repeat: repeat-y;*/
  }

  .nav-check {
    background-color: #511b80;
    height: 80px
  }

  .navbar-custom {
    padding: 5px;
  }

  .web-mobile {
    color: black;
    margin-left: 2%;
    margin-right: 2%;
    padding-top: 80px;
  }

  .left-add {
    display: none;
  }

  .right-add {
    display: none;
  }

  .center {
    width: 100%
  }

  .bottom-posts {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 6px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .bottom-img-title {
    box-shadow: 2px 2px 4px 4px rgba(45, 27, 127, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 10px;
  }

  .left-buttom-posts {

  }

  .right-bottom-posts {

  }

  .mob-webspan {
    text-align: left;
  }

  .background-opacity {
    background-image: url('https://englishhub.app/opacitylogo.png');
    background-position: center;
    background-repeat: repeat-y;
  }

  .player-wrapper {
    position: relative;
    padding-top: 75%; /* 16:9 ratio */
  }

  footer {
    width: 100%;
    background-color: rgb(222, 234, 246);
  }

  .footer-containor1 {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
  }

  .footer-containor1 h {
    font-weight: 300;
    text-decoration: underline;
    margin-bottom: 4px;
    font-size: 20px;
    font-style: inherit;
  }

  .link-file {
    border-bottom: 0.2px solid white;
    /*border-radius: 12px 12px 0 0;*/
    /*margin: auto;
    */
    width: 100%;
    display: flex;
    /*justify-content: flex-start;
    */
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #511b80;
    overflow: hidden;
  }

  .link-top {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
    width: 100%;
    height: 120px;
    align-items: center;
    margin-bottom: 10px;
    /* height: 100px; */
  }

  .link-top img {
    width: 200px;
  }

  .link-top button {
    border: 1px solid whitesmoke;
    border-radius: 35px;
    padding: 13px 50px;
    position: relative;
    background: #511b80;
    color: white;
    cursor: pointer;
    -webkit-border-radius: 35px;
    /*! width: 100px; */
    -ms-border-radius: 35px;
    -o-border-radius: 35px;
    height: fit-content;
    margin-right: 12px;
    font-size: 18px;
    font-weight: 200;
    /*! display: flex; */
    /*! text-align: center; */
    font-family: unset;
  }

  .link-top button:hover {
    color: black;
    /*! font-stretch: extra-condensed; */
    font-size: 18px;
    border: black;
    background: white;
  }

  .link-bottom {
    display: flex;
    justify-content: center;
    margin: 5px;
  }

  .link-bottom a {
    margin: 0px 12px;
    color: white;
    font-size: 1.2rem;
    padding-left: 1px;
    position: sticky;
  }

  .footer-containor2 {
    background-color: black;
    color: white;
    text-align: center;
    margin-top: 10px;
  }

  .footer-containor2 p {
    font-weight: 500;
    font-style: initial;
  }

  .footer-copyright {
    display: flex;
    background: #511b80;
    width: 100%;
    padding: 10px;
    flex-basis: 100%;
    color: white;
    font-weight: bold;
    font-style: initial;
    font-family: sans-serif;
    justify-content: center;
    align-items: center;
  }

  p {
    font-family: sans-serif;
  }

  .fontawesomesvg {
    width: 0.9em;
    height: 0.9em;
    vertical-align: -.125em;
  }
  .tiktok {
    width: 0.9em;
    height: 0.9em;
  }

  .social-menu ul {
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    display: flex;
  }

  .social-menu ul li {
    list-style: none;
  }

  .social-menu ul li .fab {
    font-size: 15px;
    line-height: 20px;
    transition: .1s;
    color: #000;
  }

  .social-menu ul li .fab:hover {
    color: #fff;
  }

  .social-menu ul li a {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #511b80;
    text-align: center;
    transition: .2s;
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, .5);
  }

  .social-menu ul li a:hover {
    transform: translate(0, -10%);
  }

  .social-menu ul li:nth-child(1) a:hover {
    background-color: rgba(39, 113, 206, 0.83);
  }

  .social-menu ul li:nth-child(2) a:hover {
    background-color: #f508e8;
  }

  .social-menu ul li:nth-child(3) a:hover {
    background-color: #fd3f06;
  }

  .social-menu ul li:nth-child(4) a:hover {
    background-color: #2d58e1;
  }

  .social-menu ul li:nth-child(5) a:hover {
    background-color: #d509f5;
  }

  .social-menu ul li:nth-child(6) a:hover {
    background-color: rgb(14, 220, 140);
  }

  .social-menu ul li:nth-child(7) a:hover {
    background-color: rgba(0, 0, 0, 0.89);
  }

}

@media only screen and (max-width: 768px) {
  .player-change {
    height: 200px
  }
  .container-dashboard {
    margin: 0px 0px 5px 0px;
    background-color: white;
  }
  .main-content {
    background-color: white;
  }
  .notification {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  .notification-button {
    background-color: #4f2bab;
    color: white;
    cursor: pointer;
    width: 100px;
    //padding:0px 30px 0px 30px
  }
  .notification-icon {
    height: 40px;
    width: 40px;
    margin-right: 30px;
    background-color: #e8e8e8;
  }
  .notification-icon-width {
    width: 20%
  }
  .mobile-button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .btn {
      float: right;
    }
  }
  .lesson-history {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .tab-button {
      background-color: transparent;
      border: none;
      color: black;
    }

    .lesson-tab-search {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
    }

    .history-search-input {
      width: 250px;
    }
  }

  .history-list {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;

    .history-card {
      display: flex;
      width: 30%;
      box-shadow: 2px 4px 4px black;

    }

    .youtube-content {
      display: flex;
      flex-direction: row;
      min-height: 200px;
    }

  }
  .write-review {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  .lesson-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    .lesson-list-content {
      border-radius: 0.375rem;
      background-color: white;
      margin-bottom: 15px;
      box-shadow: 2px 4px 4px lightgrey;
    }
  }
  .search-group {
    display: flex;
    gap: 1%;
    flex-direction: row;
    margin-left: 0%;
    margin-right: 0%;
  }
}


.youtube-content {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  min-height: 200px;
  max-height: 200px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 900px) {
  .container-dashboard {
    padding: 0px 20px;
    //background-color: #6C32A0;
  }
  .container-dashboard-refer {
    padding: 30px 20px;
    background-color: #6C32A0;
  }
  .refer-and-earn {
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    .img-fluid {
      border-radius: 50%;
      height: 120px;
      object-fit: cover;
      margin-top: -55px
    }

    .line-title {
      width: 100%;

      .divider {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 10px;
        height: 30px;
      }

      .divider-line {
        flex-grow: 1;
        border: 1px solid #6C32A0;
        border-top: 1px solid #6C32A0;
      }

      .divider-title {
        margin: 0 10px;
        font-size: 18px;
      }
    }

    .button-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 10px;
    }

    .all-btn {
      background-color: #eae9e9;
      color: #6C32A0;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 10px 2px 10px;
      gap: 10px;
      width: 30%;
      justify-content: space-between;

      &:hover {
        border: 1px solid #6C32A0;
      }

      .icon-background {
        width: 27%;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;

        .fa {
          color: #6C32A0;
        }
      }

      .total-section {
        width: 73%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .title {
          font-weight: bold;
          font-size: 13px;
          align-items: center;
          display: flex;
          color: black;
          margin-top: 2px;
        }

        .amount {
          margin-top: 0px;
          font-size: 10px;
          font-weight: bold;
          color: black;
        }

      }
    }
  }
  .recent-table {
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    background-color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    .link-web {
      display: none;
    }

    .table-heading {
      display: flex;
      flex-direction: row;
      justify-content: center;
      border-bottom: 2px solid #6C32A0;
    }

  }
  .refer-and-earn-web {
    display: none;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 900px) {
  .input-field {
    width: 60%;
  }
  .container-dashboard {
    padding: 0px;
  }

  .refer-and-earn {
    display: none;
  }
  .table-heading {
    display: none;
  }
  .refer-and-earn-web {
    .image-box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: #6C32A0;
      height: 120px;
      width: 100%;
    }

    .img-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .profile-image {
        border-radius: 50%;
        border: 3px solid white;
        object-fit: contain;
        margin-top: -110px;
      }
    }

    .stat-box {
      margin: 20px;
      margin-top: 0px;
      display: flex;
      flex-direction: row;
      gap: 1%;
      justify-content: center;

      .stat-box-button {
        padding: 10px;
        background-color: white;
        width: 24.5%;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        display: flex;
        flex-direction: column;
        align-content: space-between;
        margin-top: 10px;

        .box-text {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: space-between;
        }

        .span-icon {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background-color: #6C32A0;
          color: white;
          padding: 2px 10px 2px 10px;
          border-radius: 6px;

          &:hover {
            background-color: white;
            color: #6C32A0;
            border: 1px solid #6C32A0;
          }
        }

        .fa {
          color: #6C32A0;
          font-size: 35px;
        }

        .heading-title {
          color: #6C32A0;
          font-size: 18px;
          font-weight: bold;
        }

        .span-icon {
          margin-top: -45px;
        }
      }
    }
  }
  .recent-table {
    margin-top: 20px;
    padding: 10px;
    margin-left: 20px;
    margin-right: 20px;

    .link-mobile {
      display: none;
    }

    .table-heading {
      display: none;
    }

  }

}

.payment-transaction {
  .title {
    font-weight: bold;
    color: black;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .change-pass {
    margin-left: 28%;
  }
  .button-pad {
    padding: 5px
  }
  .profile-card {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;

    .card-body {
      padding-top: 5px;
    }

    .profile-card-detail {

      width: 100%;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      .profile-detail {
        width: 100%;
        display: flex;
        flex-direction: column;
        .fa{
          color:indigo;
        }
        span{
          font-weight: bold;
          i{
            font-size: 15px;
            width:25px;
          }
        }
      }
    }
  }
  .profile-id {
    display: flex;
    flex-direction: column;
  }
  .main-content{
    background-color: white;
  }
  .lesson-tab-button{
    padding-top: 22px;
    padding-bottom: 5px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .button-pad {
    padding: 5px;
    width: 200px;
  }
  .profile-card {
    width: 100%;
    justify-content: space-between;
    border-radius: 20px;

    .profile-card-detail {
      width: 100%;
      justify-content: flex-start;
      display: flex;
      flex-direction: row;

      .profile-detail {
        width: 50%;
        display: flex;
        flex-direction: column;
        span{
          font-weight: bold;
          i{
            font-size: 15px;
            width:20px;
            align-items: center;
            margin-right: 10px;
          }
        }
        .fa{
          color: #6C32A0
        }
          .icon-fa{
            color: #6C32A0
        }
      }
    }

  }
  .profile-id {
    width: 99%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}




